import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'

export const Section = styled.section<{ dark?: boolean }>`
  padding: 9rem 3rem 6rem;
  background: ${({ dark }) => (dark ? theme.color.background.gray : '#fff')};

  ${mq.medium} {
    padding: 14rem 5rem;
  }
`

export const Center = styled.div`
  max-width: 118rem;
  margin: auto;
`

export const SectionTitleSmall = styled.h2`
  ${heading.l};
`

export const SectionParagraph = styled.p`
  ${paragraph.small};
`

export const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 4rem;

  ${mq.medium} {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8rem;
  }

  ${mq.desktop} {
    margin-bottom: 16rem;
  }
`

export const Column = styled.div<{
  size?: Array<string | number | null>
}>`
  width: ${props => (props.size && props.size[0] ? props.size[0] : '100%')};

  ${mq.medium} {
    width: ${props => (props.size && props.size[1] ? props.size[1] : '100%')};
  }
`
