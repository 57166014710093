import React, { useState } from 'react'
import useKitPlusCryoProduct from 'hooks/use-kit-plus-cryo-product'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useModal } from 'react-modal-hook'
import { Center, Section } from '../styled'
import {
  SectionTitle,
  Paragraph,
  TableContainer,
  Table,
  TableRow,
  TableHeading,
  RowLabel,
  MobileRowLabel,
  TableCell,
  NullTableCell,
  CostTableCell,
  Check,
  QuestionMark,
  MobileQuestionMark,
  NoWrap,
  Tooltip,
  MobileModal,
  Box,
  ButtonWrap,
  DesktopOnlyCopy,
} from './styled'
import Button from 'components/button'
import { formatCurrency } from 'utils/format'

type QueryResult = {
  kitPlusCryoImage: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

interface AdditionalInfo {
  [key: string]: PopUpData
}

interface PopUpData {
  content: string
  mobileContent: string
}

const query = graphql`
  query {
    kitPlusCryoImage: file(relativePath: { eq: "kit-plus-cryo-top-view.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const ComparisonTable = () => {
  const kitPlusCryoProduct = useKitPlusCryoProduct()
  const { kitPlusCryoImage } = useStaticQuery<QueryResult>(query)

  const additionalInfo: AdditionalInfo = {
    compliant: {
      content: `We follow all guidelines from the FDA and the Society for<br/>
                  Assisted Reproductive Technology to store your sample.<br/>
                  Most fertility clinics should accept sperm stored with us.`,
      mobileContent: `We follow all guidelines from the FDA and the Society for
                        Assisted Reproductive Technology to store your sample.
                        Most fertility clinics should accept sperm stored with us.`,
    },
    virology: {
      content: `Fertility clinics need to know if your sample carries<br/>
                  infection material so that they can take the necessary<br/>
                  steps to ensure a safe and healthy embryo can be created<br/>
                  from your sperm. We are the only company that offers<br/>
                  these tests for HIV, Syphilis, Hepatitis B & C and HTLV-1<br/>
                  free of charge.`,
      mobileContent: `Fertility clinics need to know if your sample carries
                        infection material so that they can take the necessary
                        steps to ensure a safe and healthy embryo can be created
                        from your sperm. We are the only company that offers
                        these tests for HIV, Syphilis, Hepatitis B & C and HTLV-1
                        free of charge.`,
    },
    accuracy: {
      content: `Our semen analysis is validated through a peer-reviewed<br/>
                  publication in Fertility and Stertility. The paper shows<br/>
                  how our methodology meets the gold standard for<br/>
                  accuracy compared to a semen analysis performed within<br/>
                  one hour at a lab.
                  <a href="https://www.meetfellow.com/blog/fellow%E2%80%99s-accuracy-study-published-in-peer-reviewed-journal">
                    Read the paper.
                  </a>`,
      mobileContent: `Our semen analysis is validated through a peer-reviewed
                        publication in Fertility and Stertility. The paper shows
                        how our methodology meets the gold standard for
                        accuracy compared to a semen analysis performed within
                        one hour at a lab.
                        <a href="https://www.meetfellow.com/blog/fellow%E2%80%99s-accuracy-study-published-in-peer-reviewed-journal">
                          Read the paper.
                        </a>`,
    },
    lab: {
      content: `By owning and operating a CLIA-certified lab, we guarantee<br/>
                  that your information and sample is only handled by<span translate="no"> Fellow</span><br/>
                  employees. This enables us to ensure the highest quality<br/>
                  experience every step of the way.`,
      mobileContent: `By owning and operating a CLIA-certified lab, we guarantee
                        that your information and sample is only handled by<span translate="no"> Fellow</span>
                        employees. This enables us to ensure the highest quality
                        experience every step of the way.`,
    },
    results: {
      content: `We did an an analysis with 30 mailed-in samples and 30 fresh<br/>
                  samples and compared the number of living  sperm cells found<br/>
                  after freezing and thawing the sample. The takeaway:<br/>
                  there was <strong>no significant difference</strong> between the number of<br/>
                  living sperm cells found after freezing and thawing with<br/>
                  either method.`,
      mobileContent: `We did an an analysis with 30 mailed-in samples and 30 fresh
                        samples and compared the number of living  sperm cells found
                        after freezing and thawing the sample. The takeaway:
                        there was <strong>no significant difference</strong> between the number of
                        living sperm cells found after freezing and thawing with
                        either method.`,
    },
    // TODO: make these prices dynamic
    yearlyCost: {
      content: `If you choose to purchase Semen Analysis + Cryopreservation <br/>
                you’ll be charged $${formatCurrency(
                  kitPlusCryoProduct.price
                )} when you buy, and then $140/year when <br/>
                we successfully store your sample. If we cannot cryopreserve <br/>
                your sample, you will not be charged the storage fee.`,
      mobileContent: `If you choose to purchase Semen Analysis Kit + Cryopreservation,
                        you’ll be charged $${formatCurrency(
                          kitPlusCryoProduct.price
                        )} when you buy, and then $140/year when
                        we successfully store your sample. If we cannot cryopreserve your sample,
                        you will not be charged the storage fee.`,
    },
  }

  const [modalContent, setModalContent] = useState(null as any)
  const [showModal, hideModal] = useModal(
    () => (
      <MobileModal
        hideModal={hideModal}
        isOpen
        ariaHideApp={false}
        onRequestClose={hideModal}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc
      >
        <Box>
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: modalContent,
            }}
          ></p>
          <ButtonWrap>
            <Button onClick={hideModal}>Got it</Button>
          </ButtonWrap>
        </Box>
      </MobileModal>
    ),
    [modalContent]
  )

  const handleClick = (contentKey: string) => {
    setModalContent(additionalInfo[contentKey].mobileContent)
    showModal()
  }
  return (
    <Section>
      <Center>
        <SectionTitle>Created with the highest standards.</SectionTitle>
        <Paragraph>
          Not every mail-in semen analysis and cryopreservation company is the
          same. We&apos;ve done our homework to bring you flexible, affordable
          testing and storage options.
        </Paragraph>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading></TableHeading>
                <TableHeading>
                  <Img fluid={kitPlusCryoImage.childImageSharp.fluid} alt="" />
                </TableHeading>
              </tr>
              <TableRow>
                <TableHeading></TableHeading>
                <TableHeading>
                  Other mail-in&nbsp;
                  <DesktopOnlyCopy>analysis + storage&nbsp;</DesktopOnlyCopy>
                  companies
                </TableHeading>
                <TableHeading>In-person clinic</TableHeading>
                <TableHeading>
                  <span translate="no">Fellow</span>
                </TableHeading>
              </TableRow>
            </thead>
            <tbody>
              <TableRow>
                <MobileRowLabel
                  onClick={() => {
                    handleClick('lab')
                  }}
                >
                  Own and operate CLIA-certified lab&nbsp;
                  <MobileQuestionMark viewBox="0 0 16 16" />
                </MobileRowLabel>
                <RowLabel>
                  Own and operate CLIA-certified&nbsp;
                  <NoWrap>
                    lab&nbsp;
                    <QuestionMark
                      viewBox="0 0 16 16"
                      data-tip="React-tooltip"
                      data-for="lab"
                    />
                    <Tooltip
                      id="lab"
                      place="top"
                      type="light"
                      effect="solid"
                      offset={{ bottom: 8 }}
                      html
                    >
                      {additionalInfo['lab'].content}
                    </Tooltip>
                  </NoWrap>
                </RowLabel>
                <NullTableCell>&ndash;</NullTableCell>
                <TableCell>
                  <Check />
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              {/* TODO: update the below with MobileRowLabel if we ever uncomment this code */}
              {/*<TableRow>
                <RowLabel>
                  Results in the same percentage of usable sperm as a&nbsp;
                  <NoWrap>
                    clinic&nbsp;
                    <QuestionMark
                        viewBox="0 0 16 16"
                        data-tip="React-tooltip"
                        data-for="results"
                    />
                    <Tooltip
                      id="results"
                      place="top"
                      type="light"
                      effect="solid"
                      offset={{bottom: 8}}
                      html
                    >
                      {additionalInfo['results'].content}
                    </Tooltip>
                    <MobileQuestionMark
                      viewBox="0 0 16 16"
                      onClick={() => {handleClick('results')}}
                    />
                  </NoWrap>
                </RowLabel>
                <NullTableCell>&ndash;</NullTableCell>
                <TableCell>
                  <Check />
                </TableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>*/}
              <TableRow>
                <MobileRowLabel>Collect your sample from home</MobileRowLabel>
                <RowLabel>Collect your sample from home</RowLabel>
                <TableCell>
                  <Check />
                </TableCell>
                <NullTableCell>&ndash;</NullTableCell>
                <TableCell>
                  <Check />
                </TableCell>
              </TableRow>
              <TableRow>
                <MobileRowLabel>Upfront cost</MobileRowLabel>
                <RowLabel>Upfront cost</RowLabel>
                <CostTableCell>≥$440</CostTableCell>
                <CostTableCell>~$1000</CostTableCell>
                <CostTableCell>{`$${formatCurrency(
                  kitPlusCryoProduct.price
                )}`}</CostTableCell>
              </TableRow>
              <TableRow>
                <MobileRowLabel
                  onClick={() => {
                    handleClick('yearlyCost')
                  }}
                >
                  Yearly cost&nbsp;
                  <MobileQuestionMark viewBox="0 0 16 16" />
                </MobileRowLabel>
                <RowLabel>
                  Yearly&nbsp;
                  <NoWrap>
                    cost&nbsp;
                    <QuestionMark
                      viewBox="0 0 16 16"
                      data-tip="React-tooltip"
                      data-for="yearly-cost"
                    />
                    <Tooltip
                      id="yearly-cost"
                      place="top"
                      type="light"
                      effect="solid"
                      offset={{ bottom: 8 }}
                      html
                    >
                      {additionalInfo['yearlyCost'].content}
                    </Tooltip>
                  </NoWrap>
                </RowLabel>
                <CostTableCell>$99-$180</CostTableCell>
                <CostTableCell>~$300</CostTableCell>
                <CostTableCell>$140</CostTableCell>
              </TableRow>
            </tbody>
          </Table>
        </TableContainer>
      </Center>
    </Section>
  )
}

export default ComparisonTable
