import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AddToCartButton from 'components/add-to-cart-button'
import Container from 'components/container'
import IconCheck from 'components/icons/check'
import IconFlask from 'components/icons/flask'
import IconCLIA from 'components/icons/clia'
import IconFDA from 'components/icons/fda'
import {
  Hero,
  Img,
  H1,
  Intro,
  Contents,
  Price,
  ImgMobile,
  Features,
  MobileHideButtonWrapper,
} from './styled'
import useKitPlusCryoProduct from 'hooks/use-kit-plus-cryo-product'
import { formatCurrency } from 'utils/format'

type QueryResult = {
  hero: FluidImage
  heroTablet: FluidImage
  heroMobile: FluidImage
}

const query = graphql`
  query {
    hero: file(relativePath: { eq: "kit-plus-cryo-hero-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 900, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMobile: file(relativePath: { eq: "kit-plus-cryo-hero-desktop.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const KitPlusCryoHero = () => {
  const data = useStaticQuery<QueryResult>(query)
  const product = useKitPlusCryoProduct()

  return (
    <Hero>
      <Img
        // @ts-ignore
        fluid={{
          ...data.hero.childImageSharp.fluid,
        }}
        fadeIn={false}
      />
      <ImgMobile
        // @ts-ignore
        fluid={{
          ...data.heroMobile.childImageSharp.fluid,
        }}
        fadeIn={false}
      />

      <Container>
        <H1>Cryopreservation</H1>
        <Intro>
          <p>
            Add cryopreservation to your semen analysis order to help protect
            your ability to conceive through fertility treatment with a partner
            for years to come. This service is not intended to be used for
            directed donor or surrogacy.<sup>¶</sup>
          </p>
          <Contents isExpanded={true}>
            <li>
              <strong>Registered storage. </strong>Our lab is CLIA-certified and
              FDA-registered for cryopreservation.
            </li>
            <li>
              <strong>Convenient. </strong>Provide your sample in the comfort of
              your home and mail it back. Your kit ships for free.
            </li>
            <li>
              <strong>Secure. </strong>Our facility and laboratory are
              access-controlled, and storage tanks are locked and continuously
              monitored.
            </li>
          </Contents>
          {
            // Unfortunately have to hard code subscription price until
            // we can query stripe prices via graphql
          }
          <Price>
            ${formatCurrency(product.price)} + $140/year for storage
          </Price>
          <MobileHideButtonWrapper>
            <AddToCartButton product={product} CTA="Add to cart" />
          </MobileHideButtonWrapper>
          <Features>
            <li>
              <IconCheck /> Top doctors approve
            </li>
            <li>
              <IconFlask /> Privacy & Security
            </li>
            <li>
              <IconCLIA /> Own CLIA-certified lab
            </li>
            <li>
              <IconFDA /> FDA Registered
            </li>
          </Features>
        </Intro>
      </Container>
    </Hero>
  )
}

export default KitPlusCryoHero
