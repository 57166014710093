import React, { FC } from 'react'
import { ContentWrapper, Img, Paragraph } from './styled'

type Props = {
  fluid: FluidImage
  name: string
  title: string
  introduction: string
  titleIsHTML?: boolean
}

const Expert: FC<Props> = ({ fluid, name, title, introduction, titleIsHTML }) => {
  return (
    <ContentWrapper>
      <Img
        // @ts-ignore
        fluid={fluid}
        fadeIn={false}
      />
      <h3>{name}</h3>
      {titleIsHTML ? (
          <h5 dangerouslySetInnerHTML={{ __html: title }} />
        ) : (
          <h5>{title}</h5>
        )
      }
      <Paragraph>{introduction}</Paragraph>
    </ContentWrapper>
  )
}

export default Expert
