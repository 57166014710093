import React, { useEffect } from 'react'
import Layout from 'components/layout'
import ProductSEO from 'components/seo/product'
import StickyFooter from 'components/product-sticky-footer'
import Hero from './hero'
import WhyStore from './why-store'
import EasyStepsSection from './easy-steps'
import SampleIsSafe from './sample-is-safe'
import ComparisonTable from './comparison-table'
import MeetExperts from './meet-experts'
import CryoDetailSection from './cryo-detail'
import Methodology from './methodology'
import FAQ from './faq'
import useKitPlusCryoProduct from 'hooks/use-kit-plus-cryo-product'
import { formatCurrency } from 'utils/format'
import { ModalProvider } from 'react-modal-hook'
import Urls from 'common/urls'
import STITestingRequired from './sti-testing-required'
import ReferencesFootnote from 'components/references-footnote'

const KitPlusCryo = () => {
  const product = useKitPlusCryoProduct()
  useEffect(() => {
    dataLayer.push({
      event: 'eec.detail',
      ecommerce: {
        detail: {
          products: [
            {
              id: product.id,
              name: product.attributes.name,
              price: formatCurrency(product.price),
            },
          ],
        },
      },
      // @ts-ignore
      branch: window.ab_branch, // branch is defined on the window in our SEO component
    })
  }, [product.attributes.name, product.id, product.price, product.product.name])

  const title = 'Semen Analysis + Cryopreservation Kit | Doctor Reviewed'
  const seoContent = {
    title: title,
    description:
      'The at home sperm analysis kit that gives you personalized fertility results plus one year of storage. Receive lab-certified semen analysis results and ensure the protection of your fertility for as long as you need with samples stored in our state-of-the-art facility.',
    url: Urls.kitPlusCryo,
    name: title,
    price: formatCurrency(product.price),
    productImageUrl:
      'https://fellow-images.s3-us-west-2.amazonaws.com/Fellow_Semen_Analysis_Plus_Cryopreservation.png',
  }

  const references = [
    {
      symbol: '¶',
      content:
        'Assisted reproductive care is evolving and can be medically complex. We recommend that you consult with a physician prior to utilizing our cryobank to ensure our services will meet your future needs.',
    },
  ]

  return (
    <ModalProvider>
      <Layout headerPosition="fixed">
        <ProductSEO
          title={seoContent.title}
          description={seoContent.description}
          url={seoContent.url}
          name={seoContent.name}
          price={seoContent.price}
          productImageUrl={seoContent.productImageUrl}
        />
        <Hero />
        <WhyStore />
        <ComparisonTable />
        <MeetExperts />
        <EasyStepsSection />
        <STITestingRequired />
        <CryoDetailSection />
        <SampleIsSafe />
        <Methodology />
        <FAQ />
        <ReferencesFootnote references={references} />
        <StickyFooter />
      </Layout>
    </ModalProvider>
  )
}

export default KitPlusCryo
