import styled from 'styled-components'
import ImgBase from 'gatsby-image'
import { paragraph } from 'styles/typography'

export const Img = styled(ImgBase)`
  margin: 0 auto 2rem;
  max-width: 25rem;
`

export const Paragraph = styled.p`
  ${paragraph.small};
`

export const ContentWrapper = styled.div`
  padding: 0 2rem;
`
