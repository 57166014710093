import styled from 'styled-components'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'

export const Section = styled.section`
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  height: 100%;

  ${mq.desktop} {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }
`

export const ContentWrap = styled.div`
  ${mq.extraSmall} {
    padding: 5rem 0 5rem;
  }

  ${mq.desktop} {
    grid-column: 1 / 2;
    margin: auto 0;
  }
`

export const Content = styled.div`
  ${mq.desktop} {
    padding: 4rem 4rem 0 5rem;
  }

  ${mq.large} {
    width: 60rem;
    margin: auto 0 auto auto;
    padding: 0 5rem 0 6rem;
  }
`

export const Heading = styled.h1`
  ${heading.l};
`

export const ImageWrap = styled.div`
  height: 38.2rem;
  width: 100vw;
  margin-left: -3rem;
  bottom: 0;

  > div {
    height: 100%;
  }

  ${mq.desktop} {
    grid-column: 2 / end;
    grid-row: 1;
    height: 100%;
    width: inherit;
    margin: 0;
  }
`

export const Paragraph = styled.p`
  ${paragraph.small};
  max-width: 77rem;
`
