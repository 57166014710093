import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import Urls from 'common/urls'
import { OutlineButton } from 'components/button'

import {
  Content,
  ContentWrap,
  Heading,
  ImageWrap,
  Paragraph,
  Section,
  SubHeading,
} from './styled'

import { ScreenSize } from 'styles/mq'

type QueryResult = {
  technicianTakesSample: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    technicianTakesSample: file(relativePath: { eq: "technician-takes-sample.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const STITestingRequired = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section id="sti-testing-required">
      <ContentWrap>
        <Content>
          <Heading>
            Getting tested for STIs is an important step. We help make it easy.
          </Heading>
          <SubHeading>
            Fertility clinics require STI testing before they will use your sperm
            for fertilization with a partner, so it&apos;s a good idea to get it
            taken care of now.
          </SubHeading>
          <Paragraph>
            Fellow partners with
            <a href={Urls.requestATest} target="_blank" role="link"> Request A Test </a>
            to make it easy to get the STI tests you need at a LabCorp or Quest
            Diagnostics location near you. When you get your results, store them in a
            safe place so they&apos;ll be ready if you ever need to use your sample
            in the future.
          </Paragraph>
          <a href={Urls.requestATest} target="_blank" role="link">
            <OutlineButton>Learn more at Request A Test</OutlineButton>
          </a>
        </Content>
      </ContentWrap>
      <ImageWrap>
        <Img
          fluid={{
            ...data.technicianTakesSample.childImageSharp.fluid,
            sizes: `(min-width: ${ScreenSize.LG}px) 50vw, 100vw`,
          }}
          alt="A technician takes a sample from a test tube."
        />
      </ImageWrap>
    </Section>
  )
}

export default STITestingRequired
