import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const LockIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg viewBox="0 0 80 80" {...props}>
    <g
      clipPath="url(#clip0)"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path
        d="M35.336 32.437a22.908 22.908 0 018.796-1.744c3.115 0 6.086.62 8.795 1.744v-5.026a8.796 8.796 0 00-17.591 0v5.026zM27.72 37.59a22.9 22.9 0 00-6.564 16.08c0 12.689 10.287 22.975 22.976 22.975 12.689 0 22.975-10.286 22.975-22.975a22.9 22.9 0 00-6.564-16.08V27.411C60.543 18.347 53.195 11 44.132 11c-9.064 0-16.412 7.348-16.412 16.411V37.59z"
        fill="currentColor"
      />
      <path
        d="M43.422 69.448c1.016.01 2.017-.766 2.279-1.768.304-1.166-.242-2.185-.771-3.172-.174-.324-.338-.63-.473-.942-.198-.458-.068-.798.448-1.173 1.075-.78 1.56-2.348 1.128-3.646-.158-.477-.426-.901-.687-1.314l-.006-.01c-.077-.122-.157-.249-.232-.375-.859-1.434-.674-3.35.483-5.008.2-.286.787-.545 1.017-.816.413-.488.84-.992 1.093-1.576a5.52 5.52 0 00.388-3.071c-.25-1.5-1.591-3.35-3.3-3.784-1.483-.377-2.115.07-2.847.445-.67.344-1.248.895-1.669 1.595-.713 1.186-.866 2.632-.4 3.773.29.713.785 1.38 1.47 1.983.131.117.27.229.406.337.46.37 1.348.59 1.555 1.155.148.402.128.898-.052 1.362-.15.383-.407.663-.68.96-.22.24-.446.489-.614.797-.613 1.137.209 2.114.934 2.977.278.33.54.643.706.943.18.324.256.607.234.865-.029.334-.24.672-.564.906-.241.174-.537.295-.93.381-.048.01-.098.019-.151.028h-.007c-.239.04-.51.084-.666.328l-.007.011c-.079.13-.077.28-.064.42.081.865.567 1.55 1.036 2.213l.005.007c.142.202.29.41.42.616.834 1.323.992 3.003.41 4.385l-.07.166.178.002z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" transform="translate(11 11)" d="M0 0h66v66H0z"/>
      </clipPath>
    </defs>
  </Svg>
)

export default LockIcon
