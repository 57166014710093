import styled from 'styled-components'
import mq from 'styles/mq'
import { Column } from '../styled'

export const TelemedicineImageColumn = styled(Column as any)`
  margin-top: 0;
  order: 2;

  ${mq.medium} {
    order: 1;
  }
`

export const TelemedicineContentWrap = styled.div`
  ${mq.large} {
    width: 36.1rem;
  }
`

export const TelemedicineImageWrap = styled.div`
  width: 100%;

  ${mq.large} {
    margin: -8rem 1rem -11rem;
  }

  img {
    width: 100%;
  }
`

export const TelemedicineContentColumn = styled(Column as any)`
  display: flex;

  ${mq.medium} {
    order: 2;
  }

  ${mq.large} {
    padding-left: 5rem;
  }
`
