import React from 'react'
import Container from 'components/container'
import FAQAccordion, { FAQSectionCategory } from 'components/faq'
import { Section, Title } from './styled'

const FAQ = () => (
  <Section>
    <Container>
      <Title>Questions? We&apos;ve got answers.</Title>
      <FAQAccordion sectionFilters={[FAQSectionCategory.CRYO, FAQSectionCategory.SAMPLE_COLLECTION, FAQSectionCategory.ABOUT]} linkToAllFaqs />
    </Container>
  </Section>
)

export default FAQ
