import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import {
  Content,
  ContentWrap,
  Heading,
  ImageWrap,
  Paragraph,
  Section,
} from './styled'

import { ScreenSize } from 'styles/mq'

type QueryResult = {
  cryoCanister: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    cryoCanister: file(relativePath: { eq: "cryo-canister.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const SampleIsSafe = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <ContentWrap>
        <Content>
          <Heading>
            We prioritize safety and security in our state-of-the-art facility.
          </Heading>
          <Paragraph>
            Our CLIA-certified lab has the same FDA tissue banking license that
            fertility clinics must acquire in order to store seminal fluid for
            use in fertility treatments with a sexually intimate partner. Our
            lab is access controlled, and our cryo tanks are locked and
            monitored for changes in temperature to help ensure your sample is
            safe. The vials holding your sample are marked in a way that
            protects your privacy, and they can only be removed with your
            instructions. When you&apos;re ready to use your sample, we&apos;ll
            help coordinate delivery to a fertility clinic of your choosing.
            <sup>¶</sup>
          </Paragraph>
        </Content>
      </ContentWrap>
      <ImageWrap>
        <Img
          fluid={{
            ...data.cryoCanister.childImageSharp.fluid,
            sizes: `(min-width: ${ScreenSize.LG}px) 50vw, 100vw`,
          }}
          alt=""
        />
      </ImageWrap>
    </Section>
  )
}

export default SampleIsSafe
