import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import LeadEmailCaptureForm from 'components/lead-email-capture-form'

import {
  SectionParagraph,
  Center,
  Section,
  Row,
  SectionTitleSmall,
} from '../styled'

import {
  TelemedicineImageColumn,
  TelemedicineImageWrap,
  TelemedicineContentColumn,
  TelemedicineContentWrap,
} from './styled'
import { ScreenSize } from '../../../styles/mq'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  peerReviewedAndClinicallyValidated: FluidImage
  phone: FluidImage
  virology: FluidImage
}

const query = graphql`
  query {
    peerReviewedAndClinicallyValidated: file(
      relativePath: {
        eq: "kit-box-open-peer-reviewed-and-clinically-validated.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    phone: file(relativePath: { eq: "cryo-detail-phone.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    virology: file(relativePath: { eq: "sti-test.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const CryoDetailSection = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section dark>
      <Center>
        <Row>
          <TelemedicineImageColumn>
            <TelemedicineImageWrap>
              <Img
                fluid={{
                  ...data.peerReviewedAndClinicallyValidated.childImageSharp
                    .fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="An image of the Fellow Kit"
              />
            </TelemedicineImageWrap>
          </TelemedicineImageColumn>
          <TelemedicineContentColumn>
            <TelemedicineContentWrap>
              <SectionTitleSmall>
                Our process helps keep your sample safe in transit.
              </SectionTitleSmall>
              <SectionParagraph>
                Our Semen Analysis kit includes a preservation solution designed
                to stabilize your sample and a gel pack that helps maintain a
                moderate temperature during transit. Overnight shipping is
                included so your sample gets to us as quickly as possible.
              </SectionParagraph>
            </TelemedicineContentWrap>
          </TelemedicineContentColumn>
        </Row>
        <Row>
          <TelemedicineImageColumn>
            <TelemedicineImageWrap>
              <Img
                fluid={{
                  ...data.phone.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="a phone showing example results from the Fellow Cryopreservation Report"
              />
            </TelemedicineImageWrap>
          </TelemedicineImageColumn>
          <TelemedicineContentColumn>
            <TelemedicineContentWrap>
              <SectionTitleSmall>
                Get an in-depth analysis of the sperm you&apos;re storing.
              </SectionTitleSmall>
              <SectionParagraph>
                We&apos;ll email you a link to a secure webpage with your semen
                analysis results. Our report offers helpful insights into how
                your stored samples can be used by fertility clinics to enable
                you and your partner to become parents. You can bring this
                report to your doctor to discuss further.<sup>¶</sup>
              </SectionParagraph>
              <LeadEmailCaptureForm
                successMessage="Thanks! An email with our sample report is on its way."
                title="See a sample report"
                source="cryo-sample-report"
              />
            </TelemedicineContentWrap>
          </TelemedicineContentColumn>
        </Row>
      </Center>
    </Section>
  )
}

export default CryoDetailSection
