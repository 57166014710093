import React from 'react'

import Container from 'components/container'
import FlaskIcon from 'components/icons/flask'
import SpermLockIcon from 'components/icons/sperm-lock'
import FDAIcon from 'components/icons/fda'
import { Section, Heading, List } from './styled'

const Methodology = () => (
  <Section>
    <Container>
      <Heading>A Recap Of What We Offer.</Heading>

      <List>
        <li>
          <h3>Regulations</h3>
          <h4>Highest quality standards</h4>
          <p>
            We operate a CLIA-certified lab and have an FDA
            tissue banking license for the storage of seminal
            fluid for use with sexually intimate partners.
          </p>
          <FDAIcon />
        </li>
        <li>
          <h3>Convenience</h3>
          <h4>Provide your sample from home</h4>
          <p>
            With<span translate="no"> Fellow&apos;s </span>mail-in semen analysis and cryopreservation solution you have the convenience
            of producing your sample from the comfort of home, and then we&apos;ll send it to your preferred
            clinic when the time is right.
          </p>
          <FlaskIcon />
        </li>
        <li>
          <h3>Confidence &amp; Control</h3>
          <h4>Security &amp; Privacy</h4>
          <p>
            Our lab is locked 24/7 to ensure your sample is safe.
            Your sample can only be removed with your approval and
            fertility results are only shared with you and your doctor.
          </p>
          <SpermLockIcon />
        </li>
      </List>
    </Container>
  </Section>
)

export default Methodology
