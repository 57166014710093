import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

import StarSpermIcon from 'components/icons/star-sperm'

import {
  StepWrapper,
  Step,
  StepContent,
  StepNumber,
  StepTitle,
  StepIcon,
  ContentWrapper,
  Container,
  Section,
  SectionTitle,
} from './styled'

type QueryResult = {
  smallCup: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  box: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
  cryoIcon: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    smallCup: file(relativePath: { eq: "small-cup.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    box: file(relativePath: { eq: "kit-closed.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cryoIcon: file(relativePath: { eq: "cryo-icon.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const EasyStepsSection = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Container>
        <ContentWrapper>
          <SectionTitle>Get started today. It&apos;s easy.</SectionTitle>
          <StepWrapper>
            <Step>
              <StepContent>
                <StepNumber>1</StepNumber>
                <StepTitle>
                  Buy your kit and we&apos;ll ship it to you.
                </StepTitle>
              </StepContent>
              <StepIcon>
                <Img fluid={data.box.childImageSharp.fluid} />
              </StepIcon>
            </Step>
            <Step>
              <StepContent>
                <StepNumber>2</StepNumber>
                <StepTitle>
                  Provide your sample, mail it back. Get accurate fertility results.
                </StepTitle>
              </StepContent>
              <StepIcon>
                <Img fluid={data.smallCup.childImageSharp.fluid} />
              </StepIcon>
            </Step>
            <Step>
              <StepContent>
                <StepNumber>3</StepNumber>
                <StepTitle>
                  We&apos;ll freeze and store your sample. You&apos;ll get tested for STIs
                  so that you are ready for the future.
                </StepTitle>
              </StepContent>
              <StepIcon>
                <Img fluid={data.cryoIcon.childImageSharp.fluid} />
              </StepIcon>
            </Step>
            <Step>
              <StepContent>
                <StepNumber>4</StepNumber>
                <StepTitle>
                  We&apos;ll securely ship your sample to your clinic when you
                  want it.
                </StepTitle>
              </StepContent>
              <StepIcon>
                <StarSpermIcon />
              </StepIcon>
            </Step>
          </StepWrapper>
        </ContentWrapper>
      </Container>
    </Section>
  )
}

export default EasyStepsSection
