import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const QuestionMarkCircle: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewbox="0 0 24 24" fill="none">
    <path
      clipRule="evenodd"
      d="M8.002 14.153a6.154 6.154 0 100-12.307 6.154 6.154 0 000 12.307z"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.62 8.226c-.512.462-.788.778-.818 1.152H7.09c0-.552.305-1.054.857-1.576.551-.512.837-.856.837-1.24s-.187-.61-.571-.61c-.463 0-.68.245-.68.944H5.73V6.71c0-1.555 1.25-2.441 2.58-2.441 1.438 0 2.363 1.053 2.363 2.087 0 .67-.295 1.201-1.053 1.87zm-.818 1.792v1.674H7.09v-1.674h1.713z"
      fill="currentColor"
    />
  </Svg>
)

export default QuestionMarkCircle
