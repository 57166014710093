import styled from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'
import Slider from 'react-slick'

export const Section = styled.section`
  background: ${theme.color.background.gray};
  text-align: center;
  padding-top: 8rem;

  ${mq.medium} {
    padding-bottom: 6rem;
  }

  ${mq.desktop} {
    padding-top: 12rem;
  }

  .slick-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Counter = styled.span`
  ${heading.xxs};
  display: block;
  padding-top: 12rem;
`

export const SectionTitle = styled.h3`
  ${heading.xl};
  text-align: left;
`

export const Paragraph = styled.p`
  ${paragraph.small};
  text-align: left;
  max-width: 105rem;
`

export const Carousel = styled(Slider)`
  margin-top: 4rem;
  display: none !important;

  ${mq.medium} {
    display: block !important;
  }
`

export const MobileCarousel = styled(Slider)`
  margin-top: 4rem;

  ${mq.medium} {
    display: none !important;
  }
`
