import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from 'components/container'
import { Heading, Img, List, Section, Subtitle, Wrapper } from './styled'

type QueryResult = {
  spermSnowflake: FluidImage
}

const query = graphql`
  query {
    spermSnowflake: file(relativePath: { eq: "sperm-snowflake.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const WhyStore = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Container>
        <Heading>Why store your sperm?</Heading>
        <Subtitle>
          Storing your sperm now makes it available for use in in the future.
          When you and your partner are ready for IVF or other treatments,
          you&apos;ll have your healthiest sperm ready to go. Our storage is not
          intended to be used for directed donor or surrogacy.<sup>¶</sup>
        </Subtitle>
        <br />
        <Subtitle>
          Here are a few reasons why you may want to store your sample:
        </Subtitle>
        <Wrapper>
          <List>
            <li>A doctor recommended it for you</li>
            <li>
              To ensure that your healthiest sperm are available for your future
            </li>
            <li>You&apos;re not planning on having kids for years</li>
            <li>
              You&apos;re planning on getting a vasectomy and want to store
              sperm in case you and your partner decide to attempt fertility
              treatment in the future
            </li>
          </List>
          <List>
            <li>
              You&apos;re in a high risk environment (you&apos;re in the
              military or exposed to chemicals that impact fertility)
            </li>
            <li>
              You&apos;re undergoing a medical procedure or treatment that will
              reduce your fertility (chemotherapy, radiation therapy, or gender
              affirming therapy)
            </li>
          </List>
          <Img
            // @ts-ignore
            fluid={{
              ...data.spermSnowflake.childImageSharp.fluid,
            }}
            fadeIn={false}
          />
        </Wrapper>
      </Container>
    </Section>
  )
}

export default WhyStore
