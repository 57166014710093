import styled from 'styled-components'
import mq from 'styles/mq'
import { font, heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import CheckIcon from 'components/icons/tick'
import QuestionMarkCircle from 'components/icons/question-mark-circle'
import ReactModalWrapper from 'components/modal'
import ReactTooltip from 'react-tooltip'

export const SectionTitle = styled.h2`
  ${heading.xl};
  text-align: left;

  ${mq.medium} {
    text-align: center;
  }
  max-width: 90rem;
  margin: 0 auto 2rem auto;
`

export const Paragraph = styled.p`
  ${paragraph.small}
  max-width: 90rem;
  margin: auto auto 1.6rem;

  ${mq.medium} {
    text-align: center;
  }
`

export const TableContainer = styled.div`
  margin-left: -2.5rem;
  min-width: min-content;
  max-width: 90rem;

  ${mq.small} {
    margin-left: -1.75rem;
  }

  ${mq.medium} {
    margin-left: 0;
  }

  ${mq.desktop} {
    box-shadow: 0 1rem 2.5rem 0 rgba(0, 0, 0, 0.2);
    padding-top: 1rem;
    padding-bottom: 6rem;
    margin: 4rem auto 0;
  }
`

export const Table = styled.table`
  margin: 0 auto;
  border-spacing: 0;
  padding-top: 2rem;
`

export const TableRow = styled.tr`
  th,
  td {
    border-bottom: 0.1rem solid ${theme.color.border.blue};
    max-width: 22rem;

    ${mq.medium} {
      min-width: 14rem;
    }
  }

  &:last-of-type {
    td {
      border: none;
    }
  }
`

export const TableHeading = styled.th`
  ${heading.xxs};
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
  line-height: 1.5rem;
  color: ${theme.color.text.dark};
  padding: 0 0.25rem 0.8rem;

  ${mq.small} {
    padding: 0 0.5rem 0.8rem;
  }

  ${mq.medium} {
    padding: 0 1rem 0.8rem;
  }

  ${mq.desktop} {
    padding: 0 2rem 0.8rem;
  }

  div {
    margin-bottom: 0.5rem;

    ${mq.medium} {
      margin-bottom: 1rem;
    }
  }
`

export const RowLabel = styled.td`
  ${paragraph.extraSmall};
  height: 5rem;
  display: none;

  ${mq.desktop} {
    display: table-cell;
  }
`

export const MobileRowLabel = styled.td`
  ${paragraph.extraSmall};
  height: 5rem;
  cursor: pointer;
  min-width: 9rem;

  svg {
    width: 1.4rem;
  }

  ${mq.small} {
    min-width: 12rem;
  }

  ${mq.medium} {
    max-width: 22rem;
  }

  ${mq.desktop} {
    display: none;
  }
`

export const RowLabelLineBreak = styled.br`
  ${mq.small} {
    display: none;
  }
`

export const TableCell = styled.td`
  text-align: center;
  padding: 0.75rem 0;

  ${mq.medium} {
    padding: 0.75rem 1rem;
  }

  ${mq.desktop} {
    padding: 0.75rem 2rem;
  }
`

export const NullTableCell = styled(TableCell as any)`
  color: ${theme.color.border.blue};
  font-weight: 700;
`

export const CostTableCell = styled(TableCell as any)`
  ${heading.xxs};
  text-transform: none;

  &:nth-of-type(3),
  &:nth-of-type(4) {
    color: ${theme.color.text.dark};
  }

  &:nth-of-type(3) {
    padding-left: 0.5rem;
  }
`

export const Check = styled(CheckIcon)`
  color: ${theme.color.accent.secondary};
  font-size: 2.3rem;
`

export const QuestionMark = styled(QuestionMarkCircle)`
  color: ${theme.color.border.blue};
  display: none;

  ${mq.desktop} {
    display: inherit;
  }
`

export const MobileQuestionMark = styled(QuestionMarkCircle)`
  color: ${theme.color.border.blue};

  ${mq.desktop} {
    display: none;
  }
`

export const NoWrap = styled.span`
  white-space: nowrap;

  svg {
    width: 1.4rem;
  }
`

export const MobileModal = styled(ReactModalWrapper).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal',
})`
  .Modal {
    text-align: left;
    position: absolute;
    margin: auto;
    overflow: auto;
    outline: none;
  }

  .Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${mq.extraSmall} {
    .Modal {
      font-size: 0.7em;
      top: 5em;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      width: 27rem;
      height: 50rem;
    }
  }

  ${mq.small} {
    .Modal {
      font-size: 0.8em;
      top: 5em;
      width: 32rem;
      height: 59rem;
    }
  }
`

export const ButtonWrap = styled.div`
  text-align: center;
  margin: 3rem 0 0;
`

export const Tooltip = styled(ReactTooltip)`
  max-width: 45rem;
  padding: 1.6rem !important;
  box-shadow: 0 0.4rem 1.6rem -0.3rem rgb(220, 220, 220);
  font-family: ${font.base};
  font-weight: 300;
  letter-spacing: 0.03rem;
  opacity: 100% !important;

  a {
    color: ${theme.color.link.blue};
  }
`

export const Box = styled.section<{ collapsed?: boolean }>`
  background: #fff;
  margin-bottom: 2.4rem;
  padding: 4rem 2.4rem;

  ${mq.medium} {
    padding: 4rem;
  }
`

export const DesktopOnlyCopy = styled.span`
  display: none;

  ${mq.desktop} {
    display: inherit;
  }
`
