import React, { FC } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import 'slick-carousel/slick/slick.css'

import Container from 'components/container'
import sliderNavSettings from 'components/carousel-navigation'
import Expert from '../expert'
import {
  Section,
  SectionTitle,
  Paragraph,
  Carousel,
  MobileCarousel,
} from './styled'

const sliderSettings = {
  ...sliderNavSettings,
  slidesToShow: 2,
  initialSlide: 0,
}

const mobileSliderSettings = {
  ...sliderNavSettings,
  infinite: true,
  speed: 500,
  autoplay: true,
  autoplaySpeed: 8000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
}

type QueryResult = {
  dorrie: FluidImage
  barry: FluidImage
}

const query = graphql`
  query {
    dorrie: file(relativePath: { eq: "experts-dorrie.png" }) {
      childImageSharp {
        fluid(maxWidth: 275, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    barry: file(relativePath: { eq: "experts-barry.png" }) {
      childImageSharp {
        fluid(maxWidth: 275, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const MeetExperts: FC = () => {
  const data = useStaticQuery<QueryResult>(query)
  const experts = [
    {
      fluid: { ...data.dorrie.childImageSharp.fluid },
      name: 'Dorrie Lamb Ph.D.',
      title: '<span translate="no">Fellow </span>Cryopreservation Author',
      introduction: `Dr. Lamb is the Vice Chair for Research in the Department of Urology,
                      and Director of the Center for Reproductive Genomics at Weill Cornell Medicine.
                      She is highly recognized for her significant contributions in the field.
                      She is a former president of the American Society for Reproductive Medicine
                      and the Society for Male Reproduction and Urology.`,
      titleIsHTML: true,
    },
    {
      fluid: { ...data.barry.childImageSharp.fluid },
      name: 'Barry Behr Ph.D. H.C.L.D',
      title: '<span translate="no">Fellow </span>Cryopreservation Lab Director',
      introduction: `Dr. Behr is the director of the IVF Lab and the co-director of fertility and
                      reproductive health for Stanford Medicine. He is an internationally renowned
                      leader in the research and advances of human reproduction, with over 90
                      peer-reviewed publications.`,
      titleIsHTML: true,
    },
  ]
  return (
    <Section>
      <Container>
        <SectionTitle>
          Meet the world&apos;s top experts that lead our cryopreservation.
        </SectionTitle>
        <Paragraph>
          When it comes to cryopreserving your sample, the quality and safety of
          your stored sperm is imperative. We&apos;ve partnered with top
          reproductive health doctors and cryobank specialists to help ensure
          that our cryopreservation offering meets industry standards.
        </Paragraph>
        <Carousel {...sliderSettings}>
          {experts.map(({ fluid, name, title, introduction, titleIsHTML }) => (
            <Expert
              key={name}
              fluid={fluid}
              name={name}
              title={title}
              introduction={introduction}
              titleIsHTML={titleIsHTML}
            />
          ))}
        </Carousel>
        <MobileCarousel {...mobileSliderSettings}>
          {experts.map(({ fluid, name, title, introduction, titleIsHTML }) => (
            <Expert
              key={name}
              fluid={fluid}
              name={name}
              title={title}
              introduction={introduction}
              titleIsHTML={titleIsHTML}
            />
          ))}
        </MobileCarousel>
      </Container>
    </Section>
  )
}

export default MeetExperts
